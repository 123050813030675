import { FormikErrors } from 'formik'
import { isDate, isEmpty, isEqual, isObject, isPlainObject, omit, transform } from 'lodash'
import QRCode from 'qrcode'

import { NotiLabel } from './constants'
import { checkValidQRCodeFormat } from './validate'

export const hashCode = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

export const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const difference = (object: Object, base: Object) => {
  const changes = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}
export const removeFields = (object: Object, fieldnames: string[]) => {
  return fieldnames.length > 0 ? omit(object, fieldnames) : object
}

export const keyify = (obj: any, prefix = '') => {
  const result = Object.keys(obj).reduce((prev, current) => {
    const currentKey = obj[current]
    if (Array.isArray(currentKey)) {
      return [...prev, ...keyify(currentKey, `${prefix}${current}[]`)]
    } else if (typeof currentKey === 'object' && currentKey !== null) {
      return [...prev, ...keyify(currentKey, `${prefix}${current}.`)]
    }
    return [...prev, prefix + current]
  }, [])
  return result
}

export const errorFocus = (errors: FormikErrors<any>): void => {
  const keys = keyify(errors)
  if (keys.length > 0) {
    const errorElement = (document.querySelector(`[id="${keys[0]}"]`) ||
      document.querySelector(`[name="${keys[0]}"]`)) as HTMLElement
    if (errorElement?.nodeName === 'DIV') errorElement?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    else errorElement?.focus()
  }
}

export const removeEmptyObject = (array: any) => array.filter((value: any) => Object.keys(value).length !== 0)

export const deepCleanEmptyValues = (obj: any) => {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (!value) return [key, value]
      if (isEmpty(value)) return null
      if (isPlainObject(value)) {
        const newValue = deepCleanEmptyValues(value)
        if (isEmpty(newValue)) return null
      }

      return [key, value]
    })
    .filter((each) => each)
    .reduce((obj, [key, value]) => ({ ...obj, [key as string]: value }), {} as any)
}

export const hexToRgbA = (hex: string, alpha = 1) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`
  }
  throw new Error('Bad Hex')
}

export const getKeyByValue = (object: { [key: string]: any }, value: string) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const genQrcodeURL = (data: string) => {
  let canvas: HTMLCanvasElement
  canvas = document.createElement('canvas')
  QRCode.toCanvas(canvas, data, { margin: 0 })

  return canvas.toDataURL()
}

export const blobToBase64File = (blob: Blob) => {
  return new Promise((resolve, _) => {
    var reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const getOrderPlural = (count: number, str?: string) => {
  if (count === 0) return ''
  return (
    count +
    (str === NotiLabel.TOTAL_COUNT
      ? count === 1
        ? ' order'
        : ' orders'
      : count === 1
      ? ` order ${str ? str : ''}`
      : ` orders ${str ? str : ''}`)
  )
}

export const isEqualInSensitive = (a, b) => {
  if (a === b) return true

  if (isDate(a) && isDate(b)) return a.toString() === b.toString()

  if (typeof a !== 'object' || typeof b !== 'object') {
    return false
  }

  const keysA = Object.keys(a)
  const keysB = Object.keys(b)

  if (keysA.length !== keysB.length) {
    return false
  }

  for (const key of keysA) {
    if (!keysB.includes(key)) {
      return false
    }
    if (typeof a[key] === 'object' && typeof b[key] === 'object') {
      if (!isEqualInSensitive(a[key], b[key])) return false
    } else if (a[key] !== b[key]) {
      return false
    }
  }

  return true
}
export const isValidObjectId = (text: string) => {
  return text?.match(/^[0-9a-fA-F]{24}$/)
}

/**
 
  Return true when both have same truth value

  * @param a boolean
  * @param b boolean
  * @returns boolean
*/
export const doXNOR = (a: boolean, b: boolean): boolean => {
  return (a && b) || (!a && !b)
}

/**
 *
 * @param contentListId - 1-N order contentListId
 * @returns sourceOrderId and packageIndexId
 */
export const transformContentListId = (contentListId: string) => {
  const lastHyphenIndex = contentListId.lastIndexOf('-')

  return {
    sourceOrderId: contentListId.substring(0, lastHyphenIndex),
    packageIndexId: contentListId.substring(lastHyphenIndex + 1)
  }
}

export const splitNumberIntoArray = (total: number, num: number): number[] => {
  let current = total

  if (current < num) return [total]

  const result = []

  while (current > num) {
    result.push(num)
    current = current - num
  }

  if (current < num) {
    result.push(current)
    return result
  }

  if (current === num) result.push(num)

  return result
}

export const removeNullFromObject = <T extends { [key: string]: any }>(obj: T): T => {
  for (let key in obj) {
    if (obj[key] === null || key === null) {
      delete obj[key]
    }
  }
  return obj
}

export const filterEmptyValues = (obj: { [key: string]: any }): { [key: string]: any } => {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => !isEmpty(value)))
}

export const isValidJSON = (str: string): boolean => {
  try {
    JSON.parse(str)
    return true
  } catch (error) {
    return false
  }
}

/**
 * To calculate percentage
 */
export const getPercentage = (val: number, total: number): number =>
  total === 0 ? 0 : Math.round((val / total) * 100 * 100) / 100

export const getUniqueIdsFromMultipleLinesText = (uniqueIdsWithNewLineText: string): string[] => {
  if (!uniqueIdsWithNewLineText?.trim()?.length) return []

  return uniqueIdsWithNewLineText
    .split('\n')
    .map((eachText) => {
      const { valid, uniqueId } = checkValidQRCodeFormat(eachText)

      return valid ? uniqueId : null
    })
    .filter(Boolean)
}
